/**
 *	Fonts
 */

@font-face {
	font-family: 'Proxima Nova';
	src: url('@{font-url}proximanova-regular.eot?#iefix') format('embedded-opentype'),
		 url('@{font-url}proximanova-regular.woff') format('woff'),
		 url('@{font-url}proximanova-regular.ttf') format('truetype'),
		 url('@{font-url}proximanova-regular.svg#Proxima Nova') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('@{font-url}proximanova-bold.eot?#iefix') format('embedded-opentype'),
		 url('@{font-url}proximanova-bold.woff') format('woff'),
		 url('@{font-url}proximanova-bold.ttf') format('truetype'),
		 url('@{font-url}proximanova-bold.svg#Proxima Nova') format('svg');
	font-weight: 700;
	font-style: normal;
}