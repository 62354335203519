/**
 *	Features
 */

.icon-sticker-raisins {
	.whtlz(180px, 110px, -70px, 120px);
	background-image: url('@{img-url}stickers/raisins.png');
}

.icon-sticker-peach {

	.whtrz(260px, 260px, -100px, 400px);
	background-image: url('@{img-url}stickers/peach.png');

	&-shadow {
		.whtrz(230px, 30px, 270px, 420px);
		background-image: url('@{img-url}shadow/peach.png');
	}

}

.icon-sticker-hamsters {
	.whtrz(530px, 400px, 180px, 0);
	background-image: url('@{img-url}stickers/hamsters.png');
}

.icon-sticker-lips {
	.whtlz(225px, 130px, 270px, 140px, 3);
	background-image: url('@{img-url}stickers/lips.png');
}

.icon-sticker-cake {
	.whtlz(260px, 220px, 570px, 40px);
	background-image: url('@{img-url}stickers/cake.png');
}

.icon-sticker-cherry {
	.whtrz(205px, 225px, 590px, 48%, 3);
	background-image: url('@{img-url}stickers/cherry.png');
}

.icon-sticker-donut1 {

	.whtrz(260px, 260px, 560px, 0, 3);
	background-image: url('@{img-url}stickers/donut1.png');

	&-shadow {
		.whtrz(200px, 24px, 890px, 30px, 3);
		background-image: url('@{img-url}shadow/donut1.png');
	}

}

.icon-sticker-eggplant {

	.whbrz(435px, 280px, 910px, 30%, 5);
	background-image: url('@{img-url}stickers/eggplant.png');

	&-shadow {
		.whbrz(350px, 40px, 770px, 26%);
		background-image: url('@{img-url}shadow/eggplant.png');
	}

}

.icon-sticker-strawberry {
	.whtrz(115px, 145px, 1030px, 160px, 5);
	background-image: url('@{img-url}stickers/strawberry.png');
}

.icon-sticker-donut2 {
	.whblz(250px, 250px, 740px, 80px);
	background-image: url('@{img-url}stickers/donut2.png');
}

.icon-sticker-hamster3 {
	.whblz(275px, 325px, 325px, -20px, 3);
	background-image: url('@{img-url}stickers/hamster3.png');
}

.icon-sticker-stick {

	.whbrz(200px, 210px, 405px, 54%, 3);
	background-image: url('@{img-url}stickers/stick.png');

	&::after {
		.whblz(85px, 12px, -50px, -12px);
		background-image: url('@{img-url}shadow/stick.png');
		background-size: cover;
		content: '';
	}

}

.icon-sticker-lollipop {
	.whbrz(150px, 270px, 450px, 20%);
	background-image: url('@{img-url}stickers/lollipop.png');
}

.icon-sticker-hamster2 {
	.whbrz(260px, 325px, 105px, 0, 3);
	background-image: url('@{img-url}stickers/hamster2.png');
}

.icon-sticker-hamster1 {
	.whbrz(540px, 540px, -125px, 50%, 3);
	background-image: url('@{img-url}stickers/hamster1.png');
	transform: translateX(50%);
}

/**
 *	Media
 */

@media screen and (max-width: 980px) {

	.icon-sticker-hamster3,
	.icon-sticker-cherry {
		visibility: hidden;
		opacity: 0;
	}

}

@media screen and (max-width: 860px) {

	.icon-sticker-raisins,
	.icon-sticker-stick,
	.icon-sticker-stick::after {
		visibility: hidden;
		opacity: 0;
	}

}

@media screen and (max-width: @max-width-media) {

	.icon-sticker-hamster3,
	.icon-sticker-cherry,
	.icon-sticker-raisins,
	.icon-sticker-stick {
		visibility: visible;
		opacity: 1;
	}

	.icon-sticker-raisins {
		.whtlz(100px, 60px, -20px, 10%);
	}

	.icon-sticker-peach {

		.whtrz(155px, 150px, -65px, 5%);

		&-shadow {
			.whtrz(170px, 15px, 150px, 2%);
		}

	}

	.icon-sticker-lips {
		.whtlz(120px, 70px, 115px, 30%, 3);
	}

	.icon-sticker-cake {
		.whtlz(155px, 140px, 300px, 10px);
	}

	.icon-sticker-cherry {
		.whtrz(110px, 125px, 300px, 10px, 3);
	}

	.icon-sticker-hamsters {
		.whtlz(280px, 200px, 440px, 15%);
	}

	.icon-sticker-donut1 {

		.whtrz(145px, 150px, 620px, 5%, 5);

		&-shadow {
			.whtrz(150px, 15px, 800px, 15px, 3);
		}

	}

	.icon-sticker-eggplant {

		.whtrz(260px, 170px, 870px, 15%, 5);

		&-shadow {
			.whbrz(250px, 25px, 780px, 10px);
		}

	}

	.icon-sticker-strawberry {
		.whtrz(60px, 75px, 850px, 20%, 5);
	}

	.icon-sticker-donut2 {
		.whblz(140px, 140px, 630px, 40%);
	}

	.icon-sticker-stick {
		.whbrz(130px, 140px, 520px, 10px);
	}

	.icon-sticker-hamster3 {
		.whblz(135px, 160px, 450px, 25px, 3);
	}

	.icon-sticker-lollipop {
		.whbrz(60px, 110px, 310px, 55%);
	}

	.icon-sticker-hamster2 {
		.whbrz(150px, 140px, 165px, 10%, 3);
	}

	.icon-sticker-hamster1 {
		.whbrz(260px, 300px, -105px, 50%, 3);
	}

}

@media screen and (max-width: 380px) {

	.icon-sticker-hamsters {
		left: 50%;
		transform: translateX(-50%);
	}

}