/**
 *	Features
 */

.xh-features {

	position: relative;
	z-index: 25;
	background-color: @bg-color-grey;

	&-wrap {

		width: 100%;
		max-width: @max-width;
		position: relative;
		margin: 0 auto;
		padding: 360px 0 260px 0;

		[class^='icon-sticker'] {
			background-size: contain;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			transition: visibility @animation-speed,
						opacity @animation-speed;
		}

	}

	&-text {

		width: 100%;
		max-width: @max-width-short;
		margin: 0 auto;
		padding: 0 20px;

		&-clear::after {
			display: block;
			height: 0;
			clear: both;
			visibility: hidden;
			content: '';
		}

		&-item {

			width: 380px;
			position: relative;
			z-index: 2;
			padding: 80px 100px;
			line-height: 1.7em;
			background-color: @bg-color;
			font-family: @font-family-ptserif;

			strong {
				font-family: @font-family-proxima;
			}

		}

		/**
		 *	Text
		 */

		.text-1 {
			margin-left: 100px;
		}

		.text-2 {
			z-index: 4;
			float: right;
			margin-top: 60px;
			margin-right: 100px;
			margin-bottom: 170px;
		}

		.text-4 {
			float: right;
			margin-top: -125px;
			transition: margin @animation-speed;
		}

	}

	/**
	 *	Media
	 */

	@media screen and (max-width: 820px) {

		&-text .text-4 {
			margin-top: 30px;
		}

	}

	@media screen and (max-width: @max-width-media) {

		&-wrap {
			padding-top: 185px;
			padding-bottom: 200px;
		}

		&-text {

			&-item {
				width: 200px;
				padding: 30px;
				font-size: 0.8em;
				line-height: 1.45em;
			}

			/**
			 *	Text
			 */

			.text-1 {
				float: right;
				margin-left: 0;
			}

			.text-2 {
				float: none;
				margin-top: 350px;
				margin-right: 0;
				margin-bottom: 250px;
			}

			.text-3 {
				float: right;
			}

			.text-4 {
				float: none;
				margin-top: 125px;
			}

		}

	}

}