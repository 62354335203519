/**
 *	Welcome
 */

.xh-welcome {

	width: 100%;
	height: 100%;
	max-width: @max-width-short + 60px;
	min-height: 320px;
	position: relative;
	z-index: 50;
	margin: 0 auto;
	padding: 0 20px;
	background-color: @bg-color;

	&-header {

		position: absolute;
		top: 50%;
		left: 20px;
		transform: translateY(-50%);

		&-title {

			display: block;
			margin-bottom: 40px;
			font-size: 1.75em;
			line-height: 1.3em;
			font-weight: 700;
			transition: font-size @animation-speed;

			span {
				display: block;
				font-family: @font-family-ptserif;
				color: @font-color-grey;
				font-weight: 400;
			}

		}

		&-apps {

			display: block;
			list-style: none;
			font-size: 0.8em;

			&-item {

				display: inline-block;
				height: 20px;
				margin-right: 15px;

				.xh-app-icon {
					height: 100%;
					padding-left: 28px;
					line-height: 22px;
				}

				&:last-child {

					margin-right: 0;

					.xh-app-icon {
						padding-left: 33px;
					}

				}

			}

		}

		/**
		 *	Media
		 */

		@media screen and (max-width: @max-width-media) {

			&-title {
				margin-bottom: 30px;
				font-size: 1.35em;
			}

			&-apps-item {

				width: 20px;
				margin-right: 40px;

				.xh-app-icon {
					text-indent: -9999px;
				}

			}

		}

	}

	&-chat {

		display: block;
		width: 560px;
		height: 100%;
		position: relative;
		float: right;
		overflow: hidden;
		transition: width @animation-speed,
					opacity @animation-speed;

		&-loader {

			height: 55px;
			position: absolute;
			bottom: 40px;
			left: 0;
			padding: 0 20px;
			line-height: 56px;
			border-radius: 60px;
			background: @bg-color-grey;
			transform: translateY(75px);
			visibility: hidden;
			opacity: 0;
			transition: transform @animation-speed,
						visibility @animation-speed,
						opacity @animation-speed;

			span {

				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: @bg-color-blue;
				opacity: 0.75;

				.animation(3);
				.animation(@i) when (@i > 0) {
					&:nth-last-child(@{i}) {
						animation: loading 0.6s (@i / 10)*1s linear infinite;
					}
					.animation((@i - 1));
				}

			}

			&.show {
				transform: translateY(0);
				visibility: visible;
				opacity: 1;
			}

		}

		&-list {

			width: 100%;
			position: absolute;
			bottom: 40px;
			list-style: none;
			transition: height @animation-speed,
						transform @animation-speed * 2;

			&-item {

				height: 0;
				position: relative;
				margin: 5px 0;
				visibility: hidden;
				opacity: 0;
				transition: visibility @animation-speed * 2,
							opacity @animation-speed * 2;

				p {
					display: inline-block;
					padding: 0 20px;
					line-height: 55px;
					border-radius: 60px;
					background-color: @bg-color-grey;
				}

				&::after {
					background-size: contain;
					background-position: 50% 50%;
					background-repeat: no-repeat;
				}

				&.my-message {

					text-align: right;

					p {
						color: @font-color-light;
						background-color: @bg-color-blue;
					}

				}

				&.show {

					height: auto;
					visibility: visible;
					opacity: 1;

					&::after {
						transition: all @animation-speed / 2;
						animation-duration: 0.5s;
						animation-fill-mode: both;
						animation-name: bling;
					}

				}

			}

			&.hide {
				overflow: hidden;
				transform: translateY(-150%);
			}

		}

		/**
		 *	Media
		 */

		@media screen and (max-width: 980px) {
			width: 55%;
		}

		@media screen and (max-width: 820px) {
			visibility: hidden;
			opacity: 0;
		}

	}

	&-copyright {

		display: block;
		position: absolute;
		left: 20px;
		bottom: 50px;
		font-family: @font-family-ptserif;
		color: @font-color-grey;

		a {
			display: inline-block;
			width: 123px;
			height: 30px;
			margin-left: 3px;
			background: url('@{img-url}logo.svg') 50% 50% / cover no-repeat;
			text-indent: -9999px;
			vertical-align: middle;
		}

	}

}