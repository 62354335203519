/**
 *	Body
 */

body {
	width: 100%;
	min-width: @min-width;
	height: 100%;
	position: relative;
	background-color: @bg-color;
	color: @font-color;
	font-family: @font-family-proxima;
	font-size: @font-size;
	line-height: @line-height;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

html {
	height: 100%;
}

a {

	color: @font-color;
	text-decoration: none;
	transition: color @animation-speed,
				background-color @animation-speed;

	&:hover {
		color: @font-color-red;
		text-decoration: none;
	}

}

/**
 *	Keyframes
 */

@keyframes loading {

	0% {
		transform: translate(0, 0);
	}

	25% {
		transform: translate(0, -3px);
	}

	50% {
		transform: translate(0, 0);
	}

	75% {
		transform: translate(0, 3px);
	}

	100% {
		transform: translate(0, 0);
	}

}

@keyframes bling {

	0% {
		transform-origin: 50% 50%;
		transform: scale(2);
		filter: blur(2px);
		opacity: 0;
	}

	100% {
		transform-origin: 50% 50%;
		transform: scale(1);
		filter: blur(0);
		opacity: 1;
	}
}