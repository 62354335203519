/**
 *	Icons
 */

.xh-app-icon {

	display: inline-block;
	height: 30px;
	line-height: 32px;
	padding-left: 40px;
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: auto 100%;
	vertical-align: top;

	@apps-icons: appstore, googleplay, telegram;

	.for(@apps-icons);
	.-each(@name) {
		&.icon-@{name} {

			background-image: url('@{img-url}icons/@{name}.svg');

			&:hover,
			&::after {
				background-image: url('@{img-url}icons/@{name}-hover.svg');
			}

			&::after {
				width: 0;
				height: 0;
				position: absolute;
				visibility: hidden;
				opacity: 0;
				content: '';
			}

		}
	}

}