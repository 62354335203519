/**
 *	Policy
 */

.xh-policy {

	width: 100%;
	max-width: @max-width-short;
	position: relative;
	margin: 0 auto;
	padding: 20px;

	h1 {
		display: block;
		font-size: 2em;
		line-height: 2em;
	}

	h3 {
		display: block;
		font-size: 1.5em;
		line-height: 2em;
	}

	p {
		display: block;
		margin: 15px 0;
		font-size: 1em;
		line-height: 1.4em;
	}

}