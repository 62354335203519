/**
 *	Width/height/top/left/z-index
 */

.whtlz(@w: 0, @h: 0, @t: 0, @l: 0, @z: 1) {
	width: @w;
	height: @h;
	position: absolute;
	top: @t;
	left: @l;
	z-index: @z;
}

/**
 *	Width/height/top/right/z-index
 */

.whtrz(@w: 0, @h: 0, @t: 0, @r: 0, @z: 1) {
	width: @w;
	height: @h;
	position: absolute;
	top: @t;
	right: @r;
	z-index: @z;
}

/**
 *	Width/height/bottom/left/z-index
 */

.whblz(@w: 0, @h: 0, @b: 0, @l: 0, @z: 1) {
	width: @w;
	height: @h;
	position: absolute;
	bottom: @b;
	left: @l;
	z-index: @z;
}

/**
 *	Width/height/bottom/right/z-index
 */

.whbrz(@w: 0, @h: 0, @b: 0, @r: 0, @z: 1) {
	width: @w;
	height: @h;
	position: absolute;
	bottom: @b;
	right: @r;
	z-index: @z;
}