/**
 *	Chat
 */

.chat-icon-banana::after {
	.whtrz(100px, 90px, -40px, 115px);
	background-image: url('@{img-url}chat/banana.png');
	content: '';
}

.chat-icon-lips::after {
	.whtlz(150px, 90px, -75px, 150px);
	background-image: url('@{img-url}chat/lips.png');
	content: '';
}

.chat-icon-hamster2::after {
	.whtrz(200px, 180px, -85px, 110px, 3);
	background-image: url('@{img-url}chat/hamster2.png');
	content: '';
}

.chat-icon-hamster4::after {
	.whtrz(85px, 150px, -80px, 160px);
	background-image: url('@{img-url}chat/hamster4.png');
	content: '';
}

.chat-icon-donut1::after {
	.whtlz(130px, 130px, -40px, 130px, 1);
	background-image: url('@{img-url}chat/donut1.png');
	content: '';
}

.chat-icon-donut2::after {
	.whtlz(130px, 130px, -95px, 100px);
	background-image: url('@{img-url}chat/donut2.png');
	content: '';
}

.chat-icon-eggplant::after {
	.whtrz(155px, 100px, -45px, 125px);
	background-image: url('@{img-url}chat/eggplant.png');
	content: '';
}

.chat-icon-strawberry::after {
	.whtlz(90px, 115px, -50px, 190px);
	background-image: url('@{img-url}chat/strawberry.png');
	content: '';
}