/**
 *	Footer
 */

.xh-footer {

	width: 100%;
	max-width: @max-width-short;
	position: relative;
	z-index: 50;
	margin: 0 auto;
	padding: 100px 20px 50px 20px;
	font-family: @font-family-ptserif;
	text-align: center;
	font-style: italic;

	&-title {

		display: inline-block;
		margin-bottom: 40px;
		padding: 0 20px;
		background-color: @bg-color;

		&:after {
			display: inline-block;
			width: 95%;
			width: calc(~"100% - 40px");
			max-width: @max-width-short;
			height: 1px;
			position: absolute;
			left: 50%;
			z-index: -1;
			margin-top: @line-height / 2;
			transform: translateX(-50%);
			background-color: @border-color;
			content: '';
		}

	}

	&-line {
		width: 100%;
		height: 1px;
		margin: 0;
		margin-bottom: 40px;
		border: 0;
		background-color: @border-color;
	}

	&-button {

		display: inline-block;
		height: 40px;
		margin: 15px auto 30px auto;
		padding: 0 25px;
		font-size: 1em;
		line-height: 40px;
		color: @bg-color;
		border: 0;
		border-radius: 20px;
		background-color: @font-color;

		span {
			padding-left: 5px;
			font-style: normal;
			font-size: 1.1em;
			font-weight: 700;
			vertical-align: top;
		}

		&:hover,
		&:focus {
			color: @bg-color;
			background-color: @font-color-red;
			outline: none;
			cursor: pointer;
		}

		&:active {
			background-color: darken(@font-color-red, 5%);
		}

	}

	&-apps {

		display: block;
		margin-bottom: 40px;
		list-style: none;
		font-family: @font-family-proxima;
		font-style: normal;

		&-item {

			display: inline-block;
			margin-right: 25px;
			white-space: nowrap;

			&:last-child {

				margin-right: 0;

				.xh-app-icon {
					padding-left: 45px;
				}

			}

		}

		/**
		 *	Media
		 */

		@media screen and (max-width: @max-width-media) {

			margin-bottom: 0;

			&-item {

				display: block;
				margin: 0;
				padding: 40px 0;
				border-top: 1px solid @border-color;

				&:first-child {
					padding-top: 0;
					border-top: 0;
				}

			}

		}

	}

	&-social {

		display: block;
		list-style: none;

		&-item {

			display: inline-block;
			width: 30px;
			height: 30px;
			margin: 0 10px;

			a {

				display: block;
				width: 100%;
				height: 100%;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: cover;
				text-indent: -9999px;

				&:hover {
					opacity: 0.75;
				}

			}

		}

		@social-icons: rd, fb, tw;

		.for(@social-icons);
		.-each(@name) {
			.icon-@{name} {
				background-image: url('@{img-url}social/icon-@{name}.svg');
			}
		}

	}

	&-privacy-policy {

		margin-top: 20px;
		font-size: 0.8em;
		color: lighten(@font-color-grey, 20%);
		font-family: @font-family-proxima;
		font-style: normal;

		a {

			color: lighten(@font-color-grey, 20%);
			text-decoration: underline;

			&:hover {
				color: lighten(@font-color-grey, 10%);
				text-decoration: none;
			}

		}

	}

}